'use strict';

var dialog = require('./dialog');
var progress = require('./progress');
var loginDialog = require('./loginDialog');

/* -------------------------
 * WISHLIST
 * -------------------------
 */

function errorHandling(data, dialogTitle, dialogHtml) {
    if (data && typeof data === 'object' && 'message' in data) {
        console.log(data.message);
    }
    dialog.open({
        html: dialogHtml,
        options: {
            title: dialogTitle,
            draggable: false,
            open: function(event, ui) {                                    
                $('body').addClass('dialog-open');
            },
            close: function(event, ui) {
                $('body').removeClass('dialog-open');
            }
        }
    });
}

function updateCSRFToken() {
	if (pageContext && pageContext.options) {
		$.ajax({
			method: 'get',
			url: Urls.CSRFToken.Get,
			dataType: 'json',
			success: function(response) {
				pageContext.options.Token = response.token;
			}
		});
	}
}

function checkWishlistForItem(wishlistItemId) {
    if ('localStorage' in window && window['localStorage'] !== null && 'WishlistProductIDs' in window.localStorage && window.localStorage.WishlistProductIDs.length > 0) {
        var wishlistItems = JSON.parse(window.localStorage.WishlistProductIDs);
        var pageType = pageContext && 'type' in pageContext && pageContext.type ? pageContext.type : $('#wrapper').attr('class');
        var isWishlistItem = wishlistItems.filter(function(productObject) {
            if (productObject.productID == wishlistItemId) { return true }
            // If this is NOT a PDP, then we need to run additional checks to see if Master Product or any of the Variations are in the wishlist
            if (pageType != 'product') {
                if (productObject.masterID == wishlistItemId) { return true }
                if (productObject.variants.indexOf(wishlistItemId) > -1) { return true }
            }
        });
        return isWishlistItem.length > 0;
    } else {
        return false;
    }
}

function modifyWishlistInStorage(data) {
    if ('localStorage' in window && window['localStorage'] !== null) {
        if (!window.localStorage.WishlistProductIDs) {
            window.localStorage.WishlistProductIDs = '[]';
        }
        var item = 'productData' in data && data.productData.productID ? data.productData.productID : null;
        var isDataAction = data.action;
        var wishlistItems = JSON.parse(window.localStorage.WishlistProductIDs);

        if (item && isDataAction) {
            if (isDataAction == 'wishlistItemAdded') {
                wishlistItems.push(data.productData);
                window.localStorage.WishlistProductIDs = JSON.stringify(wishlistItems);
            }
            if (isDataAction == 'wishlistItemRemoved' && wishlistItems.length > 0) {
                var modifiedWishlist = wishlistItems.filter(function(productObject) {
                    if (productObject.productID != item) { return true }
                });
                window.localStorage.WishlistProductIDs = JSON.stringify(modifiedWishlist);
            }
        }
    }
}

function addWishlistEvent(event, productID) {
	var eventType = "wishlist_" + event;
	var url, image;
	var images = [];
	
	var thisTile = $('.product-tile[data-itemid="'+productID+'"]');
	var thisItem = JSON.parse($('.productCardMetadata.event-browse[data-pid="'+productID+'"]').attr('data-product-gtm-json'));
	if (thisTile.length > 0) {
		url = thisTile.find('.thumb-link').attr('href');
		image = thisTile.find('.thumb-link img').attr('src');
		images.push(image);
	} else {
		var selectedSizeOption = $('div.product-variations .size.size-options li.selected .swatchanchor');
		var selectedSizeUrl = selectedSizeOption.attr('href').replace('Product-Variation', 'Product-Show');
		var selectedSizeValue = selectedSizeOption.attr('data-size-id').replace(/ /g, '%20');
		url = selectedSizeUrl.replace("_size=", "_size="+selectedSizeValue);
		$('.product-primary-image [class*="-slide"]').not('.slick-cloned').each(function (index) {
			if(index > -1) {
				var image = $(this).find('a').attr('href');
				if (!image || image == undefined || image == null) {
					image = $(this).find('img').attr('src');
				}
				if (images.indexOf(image) === -1) {
					images.push(image);
				}
			}
		});
	}
	
	var properties = {
		"productID": thisItem["id"],
		"productName": thisItem["name"].replace(/\\/g, ""),
		"category": thisItem["category"].replace(/\\/g, ""),
		"price": thisItem["price"],
		"images": images,
		"url": url,
		"attr": {
			"manufacturer": thisItem["brand"]
		},
	};

	if (typeof crdl !== 'undefined' && typeof crdl === 'function') {
		crdl('event', eventType, properties); 
	}	
}

function prepareWishlistAddUI(buttons, wishlistItemId, sizeText) {
    if (!buttons) {
        return;
    } else {
        buttons.each(function(idx, element) {
            var $button = $(element);
            var addUrl = Urls.wishlistAdd + '?pid=' + $button.attr('data-pid');
            $button.removeClass('wishlist-item-added').addClass('wishlist-item-ready');
            $button.css('display', 'inline-block');
            $button.attr('data-url', addUrl);
            $button.attr('data-action', 'AddInternal');
            $button.attr('title', ($button.attr('data-title-add') + ' - ' + sizeText));
            if ($button.attr('id') == 'wishlist-button') {
                $button.find('span').html($button.attr('data-add-message'));
            }
        });
    }
    
    if ($('.pt_wish-list').length > 0 && (wishlistItemId)) {
        if ('localStorage' in window && window['localStorage'] !== null && 'WishlistProductIDs' in window.localStorage && window.localStorage.WishlistProductIDs.length > 0) {
            var wishlistItems = JSON.parse(window.localStorage.WishlistProductIDs);
            var variationModel = wishlistItems.filter(function(productObject) {
                if (productObject.productID == wishlistItemId || productObject.masterID == wishlistItemId || productObject.variants.indexOf(wishlistItemId) > -1) { 
                    return productObject;
                }
            });
            if (variationModel.length) {
                var wishlistItemsToRemove = variationModel[0];
                for (var key in wishlistItemsToRemove) {
                    var value = wishlistItemsToRemove[key];
                    // If matching variant or master
                    if (key == 'productID' || key == 'masterID') {
                        $('.product-tile[data-itemid="'+value+'"]').parents('li.wishlist-item').remove();
                    // Else, check the other variants
                    } else {
                        var variants = value.split(',');
                        var i = 0;
                        while (i < variants.length) {
                            var variantID = variants[i];
                            $('.product-tile[data-itemid="'+variantID+'"]').parents('li.wishlist-item').remove();
                            i++;
                        }
                    }
                }
                // If user removes all shareable products from wishlist, then hide the share toggle and set the wishlist to private
                if ($('li.wishlist-item[data-shareable-item="true"]').length < 1) {
                    $('.wishlist-share-container').hide();
                    $('.share-wishlist-toggle input[name="share-wishlist-checkbox"]').prop('checked', false).trigger('change');
                }
                if ($('li.wishlist-item').length < 1) {
                    $('.wishlist-message.empty-wishlist').removeClass('d-none');
                }
            }
        }
    }
}

function prepareWishlistRemoveUI(buttons, sizeText) {
    if (!buttons) {
        return;
    } else {
        buttons.each(function(idx, element) {
            var $button = $(element);
            var removeUrl = Urls.wishlistRemove + '?pid=' + $button.attr('data-pid');
            $button.removeClass('wishlist-item-ready').addClass('wishlist-item-added');
            $button.css('display', 'inline-block');
            $button.attr('data-url', removeUrl);
            $button.attr('data-action', 'RemoveInternal');
            $button.attr('title', ($button.attr('data-title-remove') + ' - ' + sizeText));
            if ($button.attr('id') == 'wishlist-button') {
                $button.find('span').html($button.attr('data-remove-message'));
            }
        });
    }
}

function renderAllWishlistButtons() {
    $('.wishlist-item-status-button').each(function() {
        var $button = $(this);
        var wishlistItemId = $button.attr('data-pid');
        if (wishlistItemId && wishlistItemId != '') {
            var isWishlistItem = checkWishlistForItem(wishlistItemId);
            if (isWishlistItem) { 
                prepareWishlistRemoveUI($button);
            } else {
                prepareWishlistAddUI($button, wishlistItemId);
            }
        }
    });
}

function validateEmailForm($email, $message) {	
    $('.share-wishlist-menu-popup .error-container').addClass('d-none');

    var regexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;	
    var regexMessage = /^[A-Za-z0-9 _.,!"'\/()$]*/g;
    var email = $email.val().trim();
    var message = $message.val().trim();
	var formValidation = true;
			
	if ((!email || email.length == 0) || String(email).match(regexEmail) == null) {
		formValidation = false;
        $email.addClass('error');
	    $('#share-wishlist-email-popup .invalid-email-format').removeClass('d-none');
	}
    if ((!message || message.length == 0) || String(message).match(regexMessage) == null) {
        formValidation = false;
        $message.addClass('error');
        $('#share-wishlist-email-popup .invalid-email-message-format').removeClass('d-none');
    }
			
	if (!formValidation) {
        return false;
    } else {
        return true;
    }
}

function init () {
    $(document).ready(function() {

        updateCSRFToken();

        /**
         * On page load, make AJAX call to get wishlist items and store in localStorage. From localStorage, compare the items being loaded on 
         * page to see if any are in wishlist, and mark the heart icons accordingly. 
         */
        if ('localStorage' in window && window['localStorage'] !== null) {
            // Only make AJAX call to get wishlist product IDs if customer is logged in
            if (Customer.authenticated) {
                $.ajax({
                    type: 'GET',
                    url: Urls.wishlistGetItems,
                    contentType: 'application/json',
                    error: function(jqXHR, textStatus, errorThrown) {
                        $('.wishlist-item-status-button').css('display', 'inline-block');
                        window.localStorage.WishlistProductIDs = '';
                    },
                    success: function(response) {
                        if (response) {
                            if (typeof response === 'object' && 'wishlist' in response) {
                                window.localStorage.WishlistProductIDs = JSON.stringify(response.wishlist);
                                renderAllWishlistButtons();
                            } else {
                                $('.wishlist-item-status-button').css('display', 'inline-block');
                                window.localStorage.WishlistProductIDs = '';
                            }
                        }
                    }
                });   
            // If guest user, just render the wishlist buttons as empty
            } else {
                window.localStorage.WishlistProductIDs = '';
                renderAllWishlistButtons();
            }
        }

        /**
         * On click of a wishlist heart button, open a login dialog (if guest user) or add/remove item from wishlist (if logged-in customer)
         */
        $('body').on('click', '.wishlist-item-status-button, .wishlist-button', function(event) {
            event.preventDefault();
            var $button = $(this);
            var wishlistUrl = $button.attr('data-url');
            var wishlistController = $button.attr('data-controller');
            var wishlistAction = $button.attr('data-action');
            var productID = $button.attr('data-pid');

            var isProductPage = $('.pt_product-details').length;
            var continueWishlist = true;

            if (isProductPage) {
                var sizeSelected = $('div.product-variations .size.size-options li.selected');
				var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
				var widthSelected = $('div.product-variations .width.width-options li.selected').length;
				var isSizeSelected = sizeSelected.length || (lengthSelected && widthSelected);
				var isColorSelected = $('div.product-variations .color.color-options li.selected').length;
                if (!isSizeSelected) {
                    alert('Please select a size');
                    continueWishlist = false;
                }
            }
            
            if (continueWishlist) {
            	//Cordial wishlist add/remove events
            	if ($button.hasClass('wishlist-item-added')) {
    				addWishlistEvent('remove', productID);
    			} else {
    				addWishlistEvent('add', productID);
    			}
            	
                // WISHLIST REPORTING: Track wishlist-item-status-button clicks (both guest and logged-in users)
                $.ajax({
                    type: 'POST',
                    url: Urls.wishlistTrackActions,
                    contentType: 'application/json',
                    data: JSON.stringify({
                        action: wishlistAction,
                        pid: productID
                    }),
                    error: function(jqXHR, textStatus, errorThrown) {
                        console.log('Wishlist-TrackActions: Error in incrementing wishlist action counters. ERROR TEXT: '+textStatus+', ERROR THROWN: '+errorThrown+'.');
                    },
                    success: function(response) {
                        if (response) {}
                    }
                });

                // If guest user, open a login dialog
                if (!Customer.authenticated) {
                    var params = wishlistUrl.substring(wishlistUrl.indexOf('?'), wishlistUrl.length);
                    loginDialog.show(function() {
                        // For reporting
                        $('.login-dialog-forms-container [data-login-source]').attr('data-login-source', 'wishlist-modal'); 
                        $('.login-dialog-forms-container [data-passwordless-login-source]').attr('data-passwordless-login-source', 'wishlist-modal');
                    }, wishlistController, wishlistAction, productID, params, wishlistUrl);
                // Else, if logged-in user, allow customer to add/remove wishlist item
                } else {
                    $.ajax({
                        type: 'GET',
                        url: wishlistUrl,
                        contentType: 'application/json',
                        error: function(jqXHR, textStatus, errorThrown) {
                            errorHandling(errorThrown, 'Wishlist - Error', '<p style="padding: 10px;">There was a problem handling your request. Please try again later.</p>');
                        },
                        success: function(response) {
                            if (response && response.result && response.result == 'success' && response.action) {
                                modifyWishlistInStorage(response);
                                if (response.action == 'wishlistItemRemoved') {
                                    var productIdRemoved = response && response.formattedData && response.formattedData.productID ? response.formattedData.productID : null;
                                    prepareWishlistAddUI($button, productIdRemoved);
                                } else {
                                    prepareWishlistRemoveUI($button);
                                }
                            } else {
                                var dialogHtml = '<p style="padding: 10px;">Unable to modify wishlist at this time. Please try again later.</p>';
                                if (response && response.action) {
                                    if (response.action == 'wishlistItemRemoved') {
                                        dialogHtml = '<p style="padding: 10px;">Unable to remove item from wishlist. Please try again later.</p>';
                                    } else {
                                        dialogHtml = '<p style="padding: 10px;">Unable to add item to wishlist. Please try again later.</p>';
                                    }
                                }
                                errorHandling(null, 'Wishlist - Error', dialogHtml);
                            }
                        }
                    });
                }
            }
        });

        /**
         * Add product to customer's cart when "Add to Cart" button is clicked on Wishlist page
         */
        $('body').on('click', '.wishlist-add-to-cart-button', function() {
            var $button = $(this);
            var productId = $button.attr('data-pid');
            $button.find('span').html('');
            progress.show($button.find('span'), true);

            $.ajax({
                type: 'POST',
                url: Urls.addProduct,
                data: {
                     pid: productId,
                     csrf_token: pageContext.options.Token
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    progress.hide();
                    $button.find('span').html('Add to Cart');
                    errorHandling(errorThrown, 'Wishlist - Error', '<p style="padding: 10px;">Unable to add item to cart. Please try again later.</p>');
                },
                success: function(response) {
                    var $miniCartElm = $('.mini-cart .minicart-quantity').length > 0 ? $('.mini-cart .minicart-quantity') : $('.mini-cart .btn-minicart .btn-minicart-qty');
                    var miniCartTotal;
                    if ($miniCartElm.length > 0) {
                        miniCartTotal = parseInt($miniCartElm.html().trim());
                    }  
                    miniCartTotal++;                  
                    $miniCartElm.html(miniCartTotal);
                    $button.attr('disabled', true).addClass('disabled');
                    $button.find('span').html('<i class="fa fa-check" aria-hidden="true"></i> Item Added');

                    // Only fire this AJAX call if the customer successfully added the product to their cart. On error of addProduct, do nothing.
                    if (Customer.authenticated) {
                        // WISHLIST REPORTING: Track 'Add to Cart' clicks from wishlist page
                        $.ajax({
                            type: 'GET',
                            url: Urls.wishlistTrackAddToCart,
                            contentType: 'application/json',
                            error: function(jqXHR, textStatus, errorThrown) {
                                console.log('Wishlist-TrackAddToCart: Error in incrementing wishlist Add-to-Cart counter. ERROR TEXT: '+textStatus+', ERROR THROWN: '+errorThrown+'.');
                            },
                            success: function(response) {
                                if (response) {}
                            }
                        });
                    }
                }
            });
        });

        $('body').on('click', '#mobile-share-wishlist-button', function() {
            $('.wishlist-share-mobile-container').slideToggle('slow');
        });
        $('body').on('change', '.share-wishlist-toggle input[name="share-wishlist-checkbox"]', function() {
            var status = $(this).is(':checked');
            if (status) {
                $('#share-wishlist-menu').slideDown('slow').css('display','flex');
            } else {
                $('#share-wishlist-menu').slideUp('slow').removeClass('show-share-wishlist-menu').css('display','none');
            }
            if (Customer.authenticated) {
                var wishlistID = $('input[name="current_wishlist_id"]').val().trim();
                if (wishlistID) {
                    $.ajax({
                        method: 'GET',
                        url: Urls.wishlistSetPublic,
                        data: {
                            WishlistID: wishlistID,
                            SetPublic: status
                        }
                    }).done(function(response) {
                        if (response.result == 'error') {
                            errorHandling(null, '', 'Wishlist Share - Error', '<p style="padding: 10px;">Oops, something went wrong and cannot update your Wishlist at this time. Please try again later.</p>');
                        } else if (response.result == 'success') {
                            // No on-screen visuals need to occur
                        }
                    });
                } else {
                    errorHandling(null, '', 'Wishlist Share - Error', '<p style="padding: 10px;">Oops, something went wrong and we could not retrieve your Wishlist. Please try again later.</p>');
                }
            } else {
                window.location.href = Urls.account;
            }
        });
        $('body').on('click', '.share-wishlist-menu-popup .close-button', function() {
            var $this = $(this);
            $this.parents('.share-wishlist-menu-popup').addClass('d-none');
            var shareLink = $this.parents('.share-wishlist-menu-popup').attr('id').indexOf('link-popup') > -1;
            if (shareLink) {
                $this.siblings('.form-row-button').find('button[name="share-wishlist-link-copy"]').removeClass('btn-complete disabled').html('Copy');
            }
        });
        $('body').on('click', 'button[name="share-wishlist-link-copy"]:not(.disabled)', function() {
            var $button = $(this);
            var wishlistUrl = $('#share-wishlist-link-popup .share-wishlist-url a').html();
            navigator.clipboard.writeText(wishlistUrl);
            $button.addClass('btn-complete disabled').html('<i class="fa fa-check" aria-hidden="true"></i> Copied!');
        });
        $('body').on('click', 'button[name="share-wishlist-email-send"]:not(.disabled)', function() {
            var $this = $(this);
            var $email = $('#wishlist-share-email-input');
            var $message = $('#wishlist-share-email-message');
            var isValidEmailForm = validateEmailForm($email, $message);
            if (isValidEmailForm) {
                if (Customer.authenticated) {
                    var wishlistID = $('input[name="current_wishlist_id"]').val().trim();
                    if (wishlistID) {
                        $.ajax({
                            method: 'POST',
                            url: Urls.wishlistShareByEmail,
                            data: {
                                WishlistID: wishlistID,
                                ToEmail: $email.val(),
                                EmailMessage: $message.val()
                            }
                        }).done(function(response) {
                            if (response.result == 'error') {
                                errorHandling(null, '', 'Wishlist Share - Error', '<p style="padding: 10px;">Oops, something went wrong and could not send your Wishlist email. Please try again later.</p>');
                            } else if (response.result == 'success') {
                                $this.parents('#share-wishlist-email-popup').addClass('share-wishlist-email-success');
                            }
                        });
                    } else {
                        errorHandling(null, '', 'Wishlist Share - Error', '<p style="padding: 10px;">Oops, something went wrong and we could not retrieve your Wishlist for sharing. Please try again later.</p>');
                    }
                } else {
                    window.location.href = Urls.account;
                }
            }
        });
        $('body').on('click', '#share-wishlist-menu .btn-open', function() {
            var $button = $(this);
            var $sharePopup = $button.siblings('.share-wishlist-menu-popup');
            if ($sharePopup.length) {
                $sharePopup.removeClass('d-none share-wishlist-email-success');
            }
        });
    });
}

exports.checkWishlistForItem = checkWishlistForItem;
exports.prepareWishlistAddUI = prepareWishlistAddUI;
exports.prepareWishlistRemoveUI = prepareWishlistRemoveUI;
exports.renderAllWishlistButtons = renderAllWishlistButtons;
exports.init = init;