'use strict';

var $errorBox = $('<div />', { 'id': 'errorBoxOPC', 'class': 'payment-declined p-2 mt-2', 'role': 'alert', 'tabindex': '0' });
var $errorBoxIcon = $('<i />', { 'class': 'fa fa-exclamation-triangle', 'role': 'presentation' });
var $errorBoxHeadline = $('<span />', { 'class': 'opc-error-box-headline' });
var $errorBoxMessage = $('<span />', { 'class': 'opc-error-box-message' });

function getErrorMessage(fieldType) {
	var errorMessage;
	switch (true) {
		case (fieldType.indexOf('_loyaltyNumber') > -1):
            errorMessage = window.Resources.OPC_LOYALTY_NUMBER_ERROR;
            break;
		case (fieldType.indexOf('_loyaltyZipCode') > -1):
			errorMessage = window.Resources.OPC_LOYALTY_ZIP_ERROR;
			break;
		case (fieldType.indexOf('_loyaltyPhoneNumber') > -1):
			errorMessage = window.Resources.OPC_LOYALTY_PHONE_ERROR;
			break;
		case (fieldType.indexOf('_rewardsNumber') > -1):
            errorMessage = window.Resources.OPC_REWARDS_NUMBER_ERROR;
            break;
		case (fieldType.indexOf('_rewardsZipCode') > -1):
			errorMessage = window.Resources.OPC_REWARDS_ZIP_ERROR;
			break;
		case (fieldType.indexOf('_rewardsPhoneNumber') > -1):
			errorMessage = window.Resources.OPC_REWARDS_PHONE_ERROR;
			break;
		case (fieldType.indexOf('_giftCard_number') > -1):
            errorMessage = window.Resources.OPC_GIFT_CARD_ERROR;
            break;
		case (fieldType.indexOf('_giftCard_pin') > -1):
            errorMessage = window.Resources.OPC_GIFT_CARD_PIN_ERROR;
            break;
        case (fieldType.indexOf('_privateLabelCredit_number') > -1):
            errorMessage = window.Resources.OPC_PRIVATE_LABEL_ERROR;
            break;
        case (fieldType.indexOf('_creditCard_owner') > -1):
            errorMessage = window.Resources.OPC_CARD_OWNER_ERROR;
            break;
		case (fieldType.indexOf('_creditCard_number') > -1):
			errorMessage = window.Resources.OPC_CARD_NUMBER_ERROR;
			break;
		case (fieldType.indexOf('_creditCard_cvn') > -1):
			errorMessage = window.Resources.OPC_SECURITY_CODE_ERROR;
			break;
        default:
            return;
    }
	return errorMessage;
}

function getPaymentFormErrorMessages($form) {
	if (!$form || $form.length == 0 || $form.find('.field.error').length == 0) {
		return;
	}
	var errorsList = new Array();
	$form.find('.field.error').each(function (index, element) {
		var errorMessage = getErrorMessage($(element).attr('name'));
		if (errorMessage) {
			errorsList.push(errorMessage);
		}
	});
	return errorsList;
}

function paymentErrorBox(headline, message, errorItems) {
	$errorBox.html('');

	if (!headline || !message) {
		return;
	}

	$errorBoxHeadline.html('&nbsp;' + headline + ':&nbsp;');
	$errorBoxMessage.html(message + '&nbsp;');
	$errorBox.append($errorBoxIcon).append($errorBoxHeadline).append($errorBoxMessage);

	if (errorItems && errorItems.length > 0) {
		for (var i = 0; i < errorItems.length; i++) {
			var $newErrorItem = errorListItem(errorItems[i]);
			$errorBox.append($newErrorItem);
		}
	}

	return $errorBox;
}

function errorListItem(errorMsg) {
	var $errorListItem = $('<span />', { 'class': 'opc-error-box-item' });
		$errorListItem.html(errorMsg + '&nbsp;');

	return $errorListItem;
}

exports.getErrorMessages = getPaymentFormErrorMessages;
exports.errorBox = paymentErrorBox;