'use strict';
/* global Resources, Urls */

var _ = require('lodash');
var loadingIndicator = require('../../components/LoadingIndicator');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var opcpayment = require('./opcpayment');
var util = require('../../util');

exports.initialize = initialize;

var $rewardsPaymentContainer = $('[payment-method="REWARDS_CREDIT"][has-rewardsnumber]');
var $rewardsPaymentsForm = $rewardsPaymentContainer.find('form');
var $rewardsPaymentsApplied = $rewardsPaymentContainer.find('.rewards-applied');
var $rewardsPaymentInput = $rewardsPaymentContainer.find('#dwfrm_billing_rewards_rewardsNumber');
var $rewardsEmailInput = $rewardsPaymentContainer.find('#dwfrm_billing_rewards_rewardsEmail');
var $rewardsPhoneNumberInput = $rewardsPaymentContainer.find('#dwfrm_billing_rewards_rewardsPhoneNumber');
var $rewardsZipCodeInput = $rewardsPaymentContainer.find('#dwfrm_billing_rewards_rewardsZipCode');
var $rewardsPaymentSecondaryContainer = $rewardsPaymentContainer.find('.rewards-secondary');
var $rewardsPaymentSecondarySelect = $rewardsPaymentContainer.find('#rewards-secondary-select');
var $rewardsPaymentErrorMessage = $rewardsPaymentContainer.find('span.error');
var rewardsCreditValidator;
var rewardsCreditRowTemplate = _.template($('#template-rewards-credit-row').html(), TemplateConstants.Lodash.Settings);
var rewardsCreditRecaptcha;
var options = {};
var loaded = {};

function renderRecaptcha() {
	if (loaded.Recaptcha && $('#rewards-credit-add-button').length > 0 && options && options.Recaptcha && options.Recaptcha.EnabledForRewardsCredit && options.Recaptcha.GoogleSiteKey) {
		rewardsCreditRecaptcha = grecaptcha.render('rewards-credit-add-button', {
	        sitekey: options.Recaptcha.GoogleSiteKey,
	        callback: submitRewardsCredit,
	        badge: 'bottomleft'
	    });
	}
}

function initialize(optionsParameter) {
	options = optionsParameter || {};
	
	renderRecaptcha();
	$(document).on('Recaptcha.Loaded', function(event) {
		if (!loaded.Recaptcha) {
			loaded.Recaptcha = true;
	        renderRecaptcha();
	    }
	});

	if ($rewardsPaymentSecondarySelect.closest('.opc-summary').length > 0) {
		$rewardsPaymentSecondarySelect.selectmenu({
			width: '100%',
			appendTo: '[payment-method="REWARDS_CREDIT"] .rewards-secondary',
			change: function(event, ui) {
				$rewardsPaymentSecondaryContainer.attr('secondary', ui && ui.item && ui.item.value ? ui.item.value : '');
			}
		});
	}
	
	$rewardsPaymentContainer.on('click', '.reset', function(event) {
		event.preventDefault();
		resetSecondarySelect();
	});

	$rewardsPaymentContainer.on('click', '.add', function(event) {
		event.preventDefault();
		
        if (!options || !options.Recaptcha || !options.Recaptcha.EnabledForRewardsCredit || !options.Recaptcha.GoogleSiteKey) {
            $(document).trigger('RewardsCredit.Add'); 
        }
	});
	
	$(document).on('RewardsCredit.Add', function(event, data) {
		event.preventDefault();

		if (parseFloat($('[balance-due]').first().attr('balance-due')) <= 0) {
			$rewardsPaymentErrorMessage.html(Resources.RewardsCredit.NoBalanceDue).show();
		} else {
			var rewardsSecondaryFactor = $rewardsPaymentSecondaryContainer.attr('secondary');
			rewardsCreditValidator = $rewardsPaymentsForm.validate();
            $rewardsPaymentInput.rules('add', ValidationRules.RewardsCredit.Number);

			switch (rewardsSecondaryFactor) {
				case "zipcode":
					$.validator.addMethod('zipCode', ValidationHelper.ValidateZipCode, Resources.INVALID_POSTAL_CODE);
					$rewardsZipCodeInput.rules('add', ValidationRules.ZipCode);
					break;

				default:
					break;
			}

            rewardsCreditValidator.form();

			// Adding ADA compliance error messages for giftCardPayment section
            if ($rewardsPaymentsForm.find('.field.error').length > 0) {
				$('.payment-declined').remove();
                var rewardsPaymentFormErrorMessages = opcpayment.getErrorMessages($rewardsPaymentsForm);
                var rewardsPaymentFormErrorBox = opcpayment.errorBox('Cannot Proceed', 'Please fix the following errors:', rewardsPaymentFormErrorMessages);
                if (rewardsPaymentFormErrorBox) {
                    $rewardsPaymentsApplied.after(rewardsPaymentFormErrorBox);
                }
            }
			
            if (Urls && Urls.checkRewards && rewardsCreditValidator.valid()) {
            	$rewardsPaymentsForm.hide();
            	
				var $rewardsCreditRow = $(rewardsCreditRowTemplate());
				$rewardsCreditRow = $rewardsCreditRow.appendTo($rewardsPaymentsApplied);
				$rewardsCreditRow.attr('status', 'pending');
				$rewardsCreditRow.find('.basketRewardNumber').html(util.getMaskedNumber($rewardsPaymentInput.val(), 4, 'xxxx... '));
	
				$rewardsCreditRow.find('.btn').loading({IsLabelShown: false});
				$rewardsCreditRow.find('.form-controls').loading({Label: Resources.Loading.RewardsCredit.Loading});
	
				$.ajax({
					url: data && data.Registered ? Urls.checkRewardsForRegistered : Urls.checkRewards,
					type: 'POST',
					data: {
						csrf_token:  $rewardsPaymentContainer.find('input[name="csrf_token"]').val(),
						rewardsNumber: $rewardsPaymentInput.val(),
						rewardsEmail: $rewardsEmailInput.val(),
						rewardsPhoneNumber: $rewardsPhoneNumberInput.val(),
						rewardsZipCode: $rewardsZipCodeInput.val()
					},
					dataType: 'json',
					success: function(data) {
						$rewardsPaymentErrorMessage.html('').hide();
	
						if ($rewardsCreditRow.find('.btn[loading]').length > 0) {
							$rewardsCreditRow.find('.btn').loading('destroy');
						}
						if ($rewardsCreditRow.find('.form-controls[loading]').length > 0) {
							$rewardsCreditRow.find('.form-controls').loading('destroy');
						}
	
						if (data && data.error !== 'undefined' && data.error === true) {
							$rewardsCreditRow.remove();
							$rewardsPaymentsForm.show();
							$rewardsPaymentErrorMessage.html(data.errorMsg).show();
						} else if (data && parseFloat(data.creditAmount) <= 0) {
							$rewardsPaymentContainer.attr('has-rewardsnumber', 'true');
							$rewardsCreditRow.attr('status', 'message');
							$rewardsCreditRow.find('.message').html(Resources.RewardsCredit.NoBalanceAvailable);
						} else if (data && data.creditAmountFormatted) {
							$rewardsPaymentContainer.attr('has-rewardsnumber', 'true');
							$rewardsCreditRow.find('.basketRewardAmount').html(data.creditAmountFormatted.replace('.00', ''));
							$rewardsCreditRow.find('.expiration-date').html(data.creditExpirationDate);
						}
						
						if (rewardsCreditRecaptcha != undefined) {
							grecaptcha.reset(rewardsCreditRecaptcha);
						}
					},
					error: function() {
						$rewardsCreditRow.remove();
						$rewardsPaymentsForm.show();
						$rewardsPaymentErrorMessage.html(Resources.Error.Generic).show();
						
						if (rewardsCreditRecaptcha != undefined) {
							grecaptcha.reset(rewardsCreditRecaptcha);
						}
					},
					complete: function(data, status, xhr) {
						if (data.status == 302 && data.responseText) {
							location.href = data.responseText;
						}
					}
				});
            }
		}
	});

	$rewardsPaymentContainer.on('click', '.apply', function(event) {
		event.preventDefault();

		var $rewardsCreditRow = $(this).closest('.reward-row');

		$rewardsCreditRow.find('.btn').loading({IsLabelShown: false});
		$rewardsCreditRow.find('.form-controls').loading({Label: Resources.Loading.RewardsCredit.Applying});

		$.ajax({
			url: Urls.applyRewards,
			type: 'POST',
			data: {
				csrf_token:  $rewardsPaymentContainer.find('input[name="csrf_token"]').val(),
				rewardsNumber: $rewardsPaymentInput.val(),
				rewardsEmail: $rewardsEmailInput.val(),
				rewardsPhoneNumber: $rewardsPhoneNumberInput.val(),
				rewardsZipCode: $rewardsZipCodeInput.val()
			},
			dataType: 'json',
			success: function(data) {
				$rewardsPaymentErrorMessage.html('').hide();
				if ($rewardsCreditRow.find('.btn[loading]').length > 0) {
					$rewardsCreditRow.find('.btn').loading('destroy');
				}
				if ($rewardsCreditRow.find('.form-controls[loading]').length > 0) {
					$rewardsCreditRow.find('.form-controls').loading('destroy');
				}

				if (data.error !== 'undefined' && data.error === true) {
					$rewardsCreditRow.attr('status', 'message');
					$rewardsPaymentInput.val('');
					$rewardsCreditRow.remove();
					$rewardsPaymentsForm.show();
					$rewardsPaymentErrorMessage.html(data.errorMsg).show();
				} else {
					$rewardsCreditRow.attr('status', 'applied');
					$rewardsCreditRow.find('.basketRewardNumber').html(util.getMaskedNumber(data.rewardsNumber, 4, 'xxxx... '));
					$rewardsCreditRow.find('.basketRewardAmount').html(data.rewardsCreditUsedFormatted).attr('label', Resources.RewardsCredit.RewardApplied);
					$rewardsCreditRow.find('.expiration-date').html(data.creditExpirationDate);

					var dataObject = {
						value: data.rewardsCreditUsed,
						payment_type: 'B Rewarded'
					}
					GoogleTagManagerClient.PushDataLayerForDataLayerType('AddPaymentInfo', dataObject);
					$(document).trigger('Cart.Update');
				}
			},
			error: function() {
				$rewardsCreditRow.attr('status', 'message');
				$rewardsPaymentErrorMessage.html(Resources.Error.Generic).show();
				resetForm(true);
			},
			complete: function(data, status, xhr) {
				if (data.status == 302 && data.responseText) {
					location.href = data.responseText;
				}
			}
		});
	});

	$rewardsPaymentContainer.on('click RewardsCredit.Remove', '.remove', function(event, data) {
		event.preventDefault();

		var $rewardsCreditRow = $(this).closest('.reward-row');
		$rewardsCreditRow.find('.btn').loading({IsLabelShown: false});
		$rewardsCreditRow.find('.form-controls').loading({Label: Resources.Loading.RewardsCredit.Removing});

		var payload = {
			rewardsNumber: $('input#dwfrm_billing_rewards_rewardsNumber').val()
		}

		if (data && data.ResetSessionData) {
			payload.resetSessionData = true
		}

		$.ajax({
			url: Urls.removeRewards,
			type: 'POST',
			dataType: 'json',
			data: payload,
			success: function() {
				$rewardsPaymentErrorMessage.html('').hide();

				if ($rewardsCreditRow.find('.btn[loading]').length > 0) {
					$rewardsCreditRow.find('.btn').loading('destroy');
				}
				if ($rewardsCreditRow.find('.form-controls[loading]').length > 0) {
					$rewardsCreditRow.find('.form-controls').loading('destroy');
				}

				if ($rewardsPaymentContainer.attr('has-rewardsnumber') !== 'true') {
					$rewardsPaymentInput.val('');
					$rewardsPaymentsForm.show();
					$rewardsCreditRow.attr('status', 'form');
					$rewardsPaymentsApplied.html('');
				} else {
					$rewardsCreditRow.attr('status', 'pending');
					$rewardsCreditRow.find('.basketRewardAmount').attr('label', Resources.RewardsCredit.Reward);
				}
				
				$(document).trigger('Cart.Update');
			},
            error: function() {
				if ($rewardsCreditRow.find('.btn[loading]').length > 0) {
					$rewardsCreditRow.find('.btn').loading('destroy');
				}
				if ($rewardsCreditRow.find('.form-controls[loading]').length > 0) {
					$rewardsCreditRow.find('.form-controls').loading('destroy');
				}

				$rewardsPaymentErrorMessage.html(Resources.Error.Generic).show();
			}
		});
	});
	
	$rewardsPaymentContainer.on('click', '.change', function(event) {
		event.preventDefault();
		
		$rewardsPaymentContainer.attr('has-rewardsnumber', 'false');
		if ($rewardsPaymentsApplied.find('.reward-row').attr('status') === 'applied') {
			$rewardsPaymentContainer.find('.remove').trigger('RewardsCredit.Remove');
		}
		resetForm();
	});

	$(document).off('mousedown.RewardsCreditPaymentPanel').on('mousedown.RewardsCreditPaymentPanel', function (event) {
        if (rewardsCreditValidator && $(event.target).closest($rewardsPaymentContainer).length == 0) {
            rewardsCreditValidator.resetForm();
            $rewardsPaymentErrorMessage.html('').hide();
        }
    });
	
	$(document).on('RewardsCredit.Clear', function(event) {
		event.preventDefault();
		resetForm();
	});

	$rewardsPaymentContainer.on('click', '.reset', function(event) {
		event.preventDefault();
		$rewardsPaymentsForm.show();
		$rewardsPhoneNumberInput.val('');
		$rewardsZipCodeInput.val('');
	});
	
	if ($rewardsPaymentContainer.attr('has-rewardsnumber') === 'true' && $rewardsPaymentsApplied.find('.reward-row').length == 0 && $('[checkout-mode]').attr('checkout-mode') != 'echo') {
		$(document).trigger('RewardsCredit.Add', { Registered: true });
	}
}

function resetForm(showErrorMessage) {
	if (rewardsCreditValidator) {
		rewardsCreditValidator.resetForm();
	}
	
	if (!showErrorMessage) {
		$rewardsPaymentErrorMessage.html('').hide();
	}
	
	var $rewardsCreditRow = $rewardsPaymentsApplied.find('.reward-row');
	if ($rewardsPaymentContainer.attr('has-rewardsnumber') !== 'true') {
		$rewardsPaymentInput.val('');
		$rewardsPhoneNumberInput.val('');
		$rewardsZipCodeInput.val('');
		
		$rewardsPaymentsForm.show();
		$rewardsPaymentsApplied.html('');
	} else {
		$rewardsCreditRow.find('.basketRewardAmount').attr('label', Resources.RewardsCredit.Reward);
	}

	resetSecondarySelect();
}

function resetSecondarySelect() {
	$rewardsPaymentSecondarySelect.prop('selectedIndex', 0);
	if ($rewardsPaymentSecondarySelect.closest('.opc-summary').length > 0) {
		$rewardsPaymentSecondarySelect.selectmenu('refresh');
	}
	$rewardsPaymentSecondaryContainer.attr('secondary', '');
}