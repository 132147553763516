'use strict';

var _ = require('lodash');
var loadingIndicator = require('../../components/LoadingIndicator');
var GeolocationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/Geolocation/GeolocationHelper');
var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');
var tooltip = require('../../tooltip');
var ValidationSettings = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Settings;
var ValidationRules = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/ValidationConstants').Rules;
var ValidationHelper = require('../../../../../bc_sheplersbootbarn_core/cartridge/scripts/util/ValidationHelper');
var ZipCodeToStateMap = require('../../../../../bc_sheplersbootbarn_core/cartridge/data/ZipCodeToStateMap.json');

module.exports = AddToCartOptionsStoreSearch;

function AddToCartOptionsStoreSearch() {
	this.options;
	this.validator;
	this.loaded;
	this.dataBuffer;

	this.resultContainerTemplate;
	this.resultItemTemplate;
	this.localDeliveryDecorator;

	this.$container;
	this.$form;
	this.$result;
	this.$formInputPostalCode;
	this.$storeSearchButton;
	this.$storeSearchButtonContainer;
	this.$bopisButton;
	this.$errorMessage;
	this.$inputTooltip;

	this.initialize = function($containerParameter, optionsParameter) {
		var self = this;
		if (!this.dataBuffer) {
			this.dataBuffer = {};
		}
		if (!this.loaded) {
			this.loaded = {};
		}
		this.options = $.extend({
			successCallback: null,
			errorCallback: null
		}, optionsParameter);

		this.resultContainerTemplate = _.template($('#template-bopis-result-container-addtocartoptions').html(), TemplateConstants.Lodash.Settings);
		this.resultItemTemplate = _.template($('#template-bopis-result-item-addtocartoptions').html(), TemplateConstants.Lodash.Settings);
		this.localDeliveryDecorator = _.template($('#template-bopis-localdelivery-decorator').html(), TemplateConstants.Lodash.Settings);

		this.$container = $containerParameter;
		this.$form = this.$container.find('form');
		this.$result = this.$container.find('.result');
		this.$formInputPostalCode = this.$form.find('input[name$="_postalCode"]');
		this.$storeSearchButton = this.$container.find('.btn.store-search');
		this.$storeSearchButtonContainer = this.$container.find('.btn-store-search-container');
		this.$bopisButton = this.$container.find('.btn.bopis');
		this.$errorMessage = this.$form.find('span.error');
		this.$inputTooltip = this.$formInputPostalCode.parent();
		this.$container.find('.btn.store-select').addClass('disabled');
		$('html').addClass('scroll-lock');

		if (this.options && this.options.LocalDelivery && this.options.LocalDelivery.Radius) {
			this.$container.find('.delivery-radius').html(this.options.LocalDelivery.Radius);
		}
		this.$container.attr('state', 'form');
		this.validator = this.$form.validate(ValidationSettings.Global);
		$.validator.addMethod('zipCode', ValidationHelper.ValidateZipCode, Resources.INVALID_POSTAL_CODE);
		this.$formInputPostalCode.rules('add', ValidationRules.ZipCode);

		this.$container.addClass('show');
		if (this.$container.attr('state') === 'form' && this.validator.checkForm() && !this.$storeSearchButton.hasClass('ready')) {
			this.$storeSearchButton.addClass('ready');
		}

		this.$form.off('click.apply').on('click.apply', '.btn.store-search:not([loading])', function (event) {
			event.preventDefault();
			self.$storeSearchButtonContainer.loading();
			self.search();
		});

		this.$container.off('click.apply').on('click.apply', '.btn.bopis:not([loading])', function (event) {
			event.preventDefault();

			var selectedLocation = self.dataBuffer['StoresDisplayed'][$('input[name="Bopis_StoreSearch_StoreId"]:checked').val()];
			if (selectedLocation) {
				$(document).trigger('ProductDetailPage.Bopis.AddToCart', { bopis: true, shippingOption: 'pickup', channel: self.options.Channel });
			} else {
				$(document).trigger('ProductDetailPage.Bopis.AddToCart', { channel: self.options.Channel });
			}
		});

		this.$formInputPostalCode.off('keydown.BopisStoreSearch.ValidationCheck').on('keydown.BopisStoreSearch.ValidationCheck', function (event) {
			if (self.$formInputPostalCode.val().length == 5 && parseInt(event.key, 10) == event.key && !window.getSelection().toString()) {
				event.preventDefault();
			}
		});

		this.$formInputPostalCode.off('change.BopisStoreSearch.ValidationCheck').on('change.BopisStoreSearch.ValidationCheck', function (event) {
			if (self.$formInputPostalCode.val().length > 5) {
				self.$formInputPostalCode.val(self.$formInputPostalCode.val().substr(0, 5));
			}
		});

		this.$formInputPostalCode.off('keyup.BopisStoreSearch.ValidationCheck, change.BopisStoreSearch.ValidationCheck').on('keyup.BopisStoreSearch.ValidationCheck, change.BopisStoreSearch.ValidationCheck', function (event) {
			event.stopImmediatePropagation();

			if (self.$container.attr('state') === 'form') {
				if (self.validator.checkForm() && !self.$storeSearchButton.hasClass('ready')) {
					self.$storeSearchButton.addClass('ready');
				} else if (!self.validator.checkForm() && self.$storeSearchButton.hasClass('ready')) {
					self.$storeSearchButton.removeClass('ready');
				}
			}

			if (self.$container.attr('state') === 'result') {
				self.$container.attr('state', 'form');
				self.$formInputPostalCode.val('');
			}
		});

		$(document).off('mousedown.BopisStoreSearch.ValidationClear').on('mousedown.BopisStoreSearch.ValidationClear', function (event) {
			if (self.validator && $(event.target).closest(self.$container).length == 0) {
				self.validator.resetForm();
				self.$errorMessage.html('').hide();
			}
		});

		$(document).off('Bopis.StoreSearch.Reset').on('Bopis.StoreSearch.Reset', function (event) {
			self.reset();
		});

		this.$container.off('click.BopisStoreSearch.Selection').on('click.BopisStoreSearch.Selection', 'input[name="Bopis_StoreSearch_StoreId"]', function (event) {
			var bopisStore = $('input[name="Bopis_StoreSearch_StoreId"]:checked').val();

			if (!self.$bopisButton.hasClass('ready')) {
				self.$bopisButton.addClass('ready');
			}

			$('.product-add-to-cart button').attr('disabled', 'disabled').addClass('hidden');

			if (bopisStore) {
				self.$bopisButton.attr('state', 'pickup');
			} else {
				self.$bopisButton.attr('state', 'ship');
			}
		});

		this.$container.off('click.BopisStoreSearch.NoResultsShip').on('click.BopisStoreSearch.NoResultsShip', '.btn.no-results-ship', function (event) {
			$('html').removeClass('scroll-lock');
			self.close();
			$(document).trigger('ProductDetailPage.Bopis.AddToCart', { channel: self.options.Channel });
		});

		this.$container.off('click.BopisAddToCartOptionsStoreSearch.Select').on('click.BopisAddToCartOptionsStoreSearch.Select', 'li[store]', function (event) {
			event.preventDefault();
			
			var $this = $(this);
			$this.find('input').prop('checked', true);
			self.$container.find('.btn.store-select').removeClass('disabled');
		});

		this.$container.off('click.BopisAddToCartOptionsStoreSearch.Confirm').on('click.BopisAddToCartOptionsStoreSearch.Confirm', '.btn.store-select:not(.disabled)', function (event) {
			var store = $(this).closest('input[store]').attr('store');
			var store = self.$container.find('input[name="bopis-store"]:checked').val();
			var eventName = 'Bopis.StoreSearch.Select';

			if (self.options.Events && self.options.Events.SelectStore) {
				eventName = self.options.Events.SelectStore;
				store = self.dataBuffer['StoresDisplayed'][store];
			}
			$('html').removeClass('scroll-lock');
			$(document).trigger(eventName, store);
			self.close();
		});

		this.$container.off('click.BopisAddToCartOptionsStoreSearch.Close').on('click.BopisAddToCartOptionsStoreSearch.Close', '.close', function (event) {
			$('html').removeClass('scroll-lock');
			self.close();
		});

		this.$container.off('click.UseMyLocation').on('click.UseMyLocation', '.use-my-location', function (event) {
			event.preventDefault();
			
			GeolocationHelper.GetPosition().then(function(position) {
				self.search(true, position);
			}).catch();
		});

		this.reset();
		if (this.options.StoreUIVersion == '2024Redesign' && this.$formInputPostalCode.val()) {
			this.search();
		}
	}

	this.reset = function() {
		this.$container.attr('result-count', '0');
		this.$result.html('');
	}

	this.close = function() {
		$('html').removeClass('scroll-lock');
		if (this.options.Functions && this.options.Functions.Close) {
			this.options.Functions.Close();
		} else {
			$('.bopis-add-to-cart-store-search-container').removeClass('show');
		}
	}

	this.search = function(isByGeolocation, position) {
		var self = this;
		var colorSelected = $('div.product-variations .color.color-options li.selected').length;
		var sizeSelected = $('div.product-variations .size.size-options li.selected');
		var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
		var widthSelected = $('div.product-variations .width.width-options li.selected').length;
		var isSizeSelected = sizeSelected.length || (lengthSelected && widthSelected);

		if (this.options.StoreUIVersion != '2024Redesign') {
			if (!isSizeSelected && !colorSelected) {
				this.$errorMessage.html('Please select a size and color.').show();
				return false;
			} else if (!isSizeSelected) {
				this.$errorMessage.html('Please select a size.').show();
				return false;
			} else if (!colorSelected) {
				this.$errorMessage.html('Please select a color.').show();
				return false;
			}
		}
		this.$errorMessage.html('').hide();
		this.$storeSearchButton.removeAttr('disabled');

		var geolocationPosition;
		if (isByGeolocation && position && position.coords) {
			geolocationPosition = {
				coords: {
					accuracy: position.coords.accuracy,
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				},
				timestamp: position.timestamp
			}
		}

		var isValid = isByGeolocation || this.validator.valid();
		if (Urls && Urls.Bopis.GetStoreInventoryForProductForPostalCode && isValid) {
			this.$result.html('');
			this.$result.loading();

			self.loaded['LocationData'] = false;
			self.loaded['InventoryData'] = false;
			self.dataBuffer['LocationData'] = null;
			self.dataBuffer['InventoryData'] = null;

			var getLocationUrl;
			var getLocationData;
			if (isByGeolocation) {
				getLocationUrl = Urls.Bopis.GetLocationsForGeolocationPosition;
				getLocationData = {
					Channel: this.options.Channel,
					Source: this.options.Source,
					GeolocationPosition: geolocationPosition
				}
			} else {
				getLocationUrl = Urls.Bopis.GetLocationsForPostalCode;
				getLocationData = {
					Channel: this.options.Channel,
					Source: this.options.Source,
					PostalCode: this.$formInputPostalCode.val()
				};
			}

			$.ajax({
				method: 'POST',
				url: getLocationUrl,
				contentType: 'application/json',
				data: JSON.stringify(getLocationData),
				dataType: 'json',
				success: function(data) {
					var locationData;
					if (data && data.ServiceSuccessfullyCalled && data.InquiryResult && data.InquiryResult.data && data.InquiryResult.data.length > 0) {
						locationData = data.InquiryResult.data;

						if (locationData && locationData.length > 0) {
							locationData = locationData.sort(function(left, right) {
								return left.distance - right.distance;
							});

							self.dataBuffer['LocationData'] = locationData;
						}
					}

					self.loaded['LocationData'] = true;
					self.render();
				},
				error: function() {
					if (self.options.errorCallback) {
						self.options.errorCallback();
					}
				}
			});

			var productId = $('.product-add-to-cart input[name="pid"]').val();
			var getStoreInventoryUrl;
			var getStoreInventoryData;
			if (isByGeolocation) {
				getStoreInventoryUrl = Urls.Bopis.GetStoreInventoryForProductForGeolocationPosition;
				getStoreInventoryData = {
					Channel: this.options.Channel,
					Source: this.options.Source,
					ProductId: productId,
					GeolocationPosition: geolocationPosition
				}
			} else {
				getStoreInventoryUrl = Urls.Bopis.GetStoreInventoryForProductForPostalCode;
				getStoreInventoryData = {
					Channel: this.options.Channel,
					Source: this.options.Source,
					ProductId: productId,
					PostalCode: this.$formInputPostalCode.val(),
					SavePostalCode: true
				};
			}

			$.ajax({
				method: 'POST',
				url: getStoreInventoryUrl,
				contentType: 'application/json',
				data: JSON.stringify(getStoreInventoryData),
				dataType: 'json',
				success: function(data) {
					if (data && data.ServiceSuccessfullyCalled && data.InquiryResult && data.InquiryResult.data && data.InquiryResult.data.length > 0 && data.InquiryResult.data[0].inventory_supplies) {
						var inquiryResult = data.InquiryResult.data[0].inventory_supplies;
						
						if (inquiryResult && inquiryResult.length > 0) {
							self.dataBuffer['InventoryData'] = [];
							
							for (var inventoryRecordElement in inquiryResult) {
								var inventoryRecord = inquiryResult[inventoryRecordElement];
								self.dataBuffer['InventoryData'][inventoryRecord.location.code] = inventoryRecord;
							}
						}
					}

					self.loaded['InventoryData'] = true;
					self.render();
				},
				error: function() {
					if (self.$result.attr('loading')) {
						self.$result.loading('destroy');
					}

					if (self.options.errorCallback) {
						self.options.errorCallback();
					}
				}
			});
		}
	}

	this.render = function() {
		if (this.loaded['LocationData'] && this.loaded['InventoryData']) {
			if (this.$storeSearchButtonContainer.attr('loading')) {
				this.$storeSearchButtonContainer.loading('destroy');
			}
			if (this.$result.attr('loading')) {
				this.$result.loading('destroy');
			}

			this.$container.attr('state', 'result');
			if (this.dataBuffer['LocationData'] && this.dataBuffer['LocationData'].length > 0) {
				this.dataBuffer['StoresDisplayed'] = [];

				var $addToCartOptions = $('.bopis-add-to-cart-options');
				var quantitySourceType;
				var dropshipLeadDays;
				if ($addToCartOptions.length > 0) {
					quantitySourceType = $addToCartOptions.attr('quantity-source-type');
					dropshipLeadDays = parseInt($addToCartOptions.attr('dropship-lead-days'), 10);
				}
				var quantitySourceTypeOffset = 0;
				if (quantitySourceType == 'WHS') {
					quantitySourceTypeOffset = -1;
				}

				var storeListHtml = '';
				var isStoreSelected = false;
				var status = '';
				var statusDisplay = '';
				var isLocalDeliverySupported = false;
				var localDeliveryHtml = '';
				var storesDisplayedCount = 0;
				var selectedStoreAttribute;
				var quantityAvailable = false;
				for (var locationKey in this.dataBuffer['LocationData']) {
					var location = this.dataBuffer['LocationData'][locationKey];

					var inventoryRecord;
					if (this.dataBuffer['InventoryData'] && this.dataBuffer['InventoryData'][location.code]) {
						inventoryRecord = this.dataBuffer['InventoryData'][location.code];
					}

					status = 'unavailable';
					statusDisplay = 'Unavailable';
					isLocalDeliverySupported = false;
					localDeliveryHtml = '';
					if (inventoryRecord && inventoryRecord.detail && inventoryRecord.detail.quantity > 0 && !this.dataBuffer['StoresDisplayed'][inventoryRecord.location.code] && (!this.options.LocalDeliveryOnly || (inventoryRecord.location.local_delivery_enabled && this.isLocationInLocalDeliveryRadius(inventoryRecord.location)))) {
						if (this.options && this.options.SelectedLocations == inventoryRecord.location.code) {
							isStoreSelected = true;
							selectedStoreAttribute ='checked';
						} else {
							selectedStoreAttribute = '';
						}
						
						if (this.options.LocalDelivery.Enabled && this.isLocationInLocalDeliveryRadius(inventoryRecord.location) && inventoryRecord.location.local_delivery_enabled) {
							localDeliveryHtml = this.localDeliveryDecorator();
							isLocalDeliverySupported = true;
						}

						if (isLocalDeliverySupported && inventoryRecord.detail && inventoryRecord.detail.quantity > 0) {
							if (this.options.LocalDeliveryOnly) {
								statusDisplay = 'Same-Day Delivery<br/>Available';
								status = 'available';
							} else if (!this.options.LocalDeliveryOnly) {
								// statusDisplay = 'Pickup & Same-Day<br/>Delivery Available';
								statusDisplay = 'Available Today';
								status = 'available';
							}
						} else if (!this.options.LocalDeliveryOnly && inventoryRecord.detail && inventoryRecord.detail.quantity > 0) {
							statusDisplay = 'Available Today';
							status = 'available';
						}

						quantityAvailable = inventoryRecord.detail.quantity;
					} else if (!this.options.LocalDeliveryOnly && pageContext.site != SiteConstants.SiteIds.BootBarnRspUS) {
						statusDisplay = 'Arrives in ' + (this.options.ShippingMethods.ShipToStore.DayRangeStart + dropshipLeadDays + quantitySourceTypeOffset) + '-' + (this.options.ShippingMethods.ShipToStore.DayRangeEnd + dropshipLeadDays + quantitySourceTypeOffset) + '<br/>Business Days';
						status = 'available';
					} else {
						quantityAvailable = false
					}

					var locationName;
					if (this.options && this.options.StoreUIVersion == '2024Redesign') {
						locationName = '<strong>' + location.StoreInfo.Name + ' - ' + location.StoreInfo.StoreId + '</strong>';
					} else {
						locationName = '<strong>' + location.StoreInfo.Name + '</strong>';
					}
					if (this.options && this.options.AgentStoreID == location.code) {
						locationName += '<br/>(Your Store)';
					}

					storeListHtml += this.resultItemTemplate({
						StoreId: location.code,
						QuantityAvailable: quantityAvailable,
						Html: '<span class="store-name">' + locationName + '</span><br/>' + location.StoreInfo.Address1 + '<br/>' + location.StoreInfo.City + ', ' + location.StoreInfo.StateCodeParsed + ', ' + location.StoreInfo.PostalCode.substr(0, 5),
						Selected: selectedStoreAttribute,
						LocalDelivery: localDeliveryHtml,
						Status: status,
						StatusDisplay: statusDisplay
					});

					storesDisplayedCount += 1;
					this.dataBuffer['StoresDisplayed'][location.code] = inventoryRecord ? inventoryRecord : { location: location };
				}

				var resultHtml = this.resultContainerTemplate({
					Html: storeListHtml
				});

				if (isStoreSelected) {
					this.$bopisButton.addClass('ready');
				}

				if (storesDisplayedCount > 0) {
					this.$result.html(resultHtml);
					this.$container.attr('result-count', storesDisplayedCount);
					this.$container.find('.result-container').show();
					this.$container.find('.result-empty').hide();
				} else {
					this.$container.attr('result-count', 0);
					this.$container.find('.result-container').hide();
					this.$container.find('.result-empty').show();
				}

				tooltip.init({
					items: '.tooltip'
				}, this.$result);

				if (this.options.successCallback) {
					this.options.successCallback(inquiryResult);
				}
			} else {
				$('.product-add-to-cart button').removeAttr('disabled').removeClass('hidden');
				this.reset();						
				this.$result.closest('.result-container').hide();
				this.$container.find('.result-empty').show();

				if (this.options.errorCallback) {
					this.options.errorCallback();
				}
			}
		}
	}

	this.isLocationInLocalDeliveryRadius = function(location) {
		var localDeliveryRadius;
		if (this.options && this.options.LocalDelivery) {
			if (location.local_delivery_vendor && location.local_delivery_vendor == 'GoLocal') {
				localDeliveryRadius = this.options.LocalDelivery.RadiusForGoLocal;
			} else {
				localDeliveryRadius = this.options.LocalDelivery.RadiusForRoadie;
			}
		}	

		if (localDeliveryRadius && location && location.distance && localDeliveryRadius >= location.distance) {
			return true;
		}

		return false;
	}
}