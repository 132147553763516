'use strict';

exports.Settings = {
	Global: {
	    errorClass: 'error',
	    errorElement: 'span',
	    onkeyup: false,
	    errorPlacement: function (error, element) {
			var $validationErrorContainer = element.closest('form').find('.validation-error-container');
			if ($validationErrorContainer.length > 0) {
				error.appendTo($validationErrorContainer);
	    	} else if (element.attr('error-container')) {
	    		error.appendTo($(element.attr('error-container')));
	    	} else {
	    		error.appendTo(element.parent());
	    	}
	    }
	},
	Checkout: {
	    errorPlacement: function(error, element) {
	    	if (element.attr('error-container')) {
	    		error.appendTo($(element.attr('error-container')));
	    	} else {
	    		error.appendTo(element.parent());
	    	}

			var $validationErrorContainer = element.closest('form').find('.validation-error-container');
			if ($validationErrorContainer.length > 0) {
				var $element = $(element);
				var $elementValidationError = $validationErrorContainer.find('.validation-error[field="' + $element.attr('id') + '"]');
				if ($elementValidationError.length <= 0) {
					var validationErrorText = $('<span class="validation-error" field="' + $element.attr('id') + '" />').text($element.closest('.formfield').find('label span').text() + ' is required.');
					$validationErrorContainer.append(validationErrorText);
				}
			}
	    },
		onfocusout: function(element) {
			if (!this.checkable(element)) {
				var isElementValid = this.element(element);
				var $element = $(element);
				var $validationErrorContainer = $element.closest('form').find('.validation-error-container');
				var $elementValidationError = $validationErrorContainer.find('.validation-error[field="' + $element.attr('id') + '"]');
				if (isElementValid && $elementValidationError.length > 0) {
					$elementValidationError.remove();
				} else if (!isElementValid && $elementValidationError.length <= 0) {
					var validationErrorText = $('<span class="validation-error" field="' + $element.attr('id') + '" />').text($element.closest('.formfield').find('label span').text() + ' is required.');
					$validationErrorContainer.append(validationErrorText);
				}
			}
		}
	}
}

exports.Rules = {
	RequiredExcludingPunctuation: {
		requiredExcludingPunctuation: true
	},
	ValidateAddressForPostOfficeBox: {
		postOfficeBox: true
	},
	ValidateAddressForPostOfficeBoxOnly: {
		postOfficeBoxOnly: true
	},
	Email: {
		email: true,
		minlength: 1
	},
	OrderNumber: {
		orderNumber: true
	},
	PostalCode: {
		postalCode: true
	},
	PostalCodeAndStateCode: {
		postalCode: true,
		zipCodeForStateCode: true
	},
	ZipCode: {
		zipCode: true
	},
	ZipCodeAndStateCode: {
		zipCode: true,
		zipCodeForStateCode: true
	},
	StateCodeForCaliforniaStateLine: {
		stateCodeForCaliforniaStateLine: true
	},
	GiftCard: {
		Number: {
			required: true,
			giftCardNumberLength: true,
			giftCardNumberValues: true
		},
		Pin: {
			required: true,
			digits: true,
			minlength: 4,
			maxlength: 8,
			messages: {
				minlength: $.validator.format(Resources.Validation.Messages.Numbers.MinimumLength),
				maxlength: $.validator.format(Resources.Validation.Messages.Numbers.MaximumLength)
			}
		}
	},
	RewardsCredit: {
		Number: {
			required: true,
			digits: true,
			minlength: 16,
			maxlength: 16,
			messages: {
				minlength: $.validator.format(Resources.Validation.Messages.Numbers.MinimumLength),
				maxlength: $.validator.format(Resources.Validation.Messages.Numbers.MaximumLength)
			}
		}
	},
	LoyaltyCredit: {
		Number: {
			required: true,
			digits: true,
			minlength: 1,
			maxlength: 10,
			messages: {
				minlength: $.validator.format(Resources.Validation.Messages.Numbers.MinimumLength),
				maxlength: $.validator.format(Resources.Validation.Messages.Numbers.MaximumLength)
			}
		}
	},
	PaymentVoucher: {
		Number: {
			required: true,
			digits: false,
			minlength: 1,
			maxlength: 28,
			messages: {
				minlength: $.validator.format(Resources.Validation.Messages.Numbers.MinimumLength),
				maxlength: $.validator.format(Resources.Validation.Messages.Numbers.MaximumLength)
			}
		}
	}
}