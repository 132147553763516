'use strict';

var _ = require('lodash');
var ajax = require('../../ajax');
var image = require('./image');
var GoogleTagManagerClient = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/util/GoogleTagManagerClient');
var productStoreInventory = require('../../storeinventory/product');
var rating = require('../../rating');
var tooltip = require('../../tooltip');
var util = require('../../util');
var bopisAddToCartOptions = require('../../bopis/components/AddToCartOptions');
var bopisStoreSearch = require('../../bopis/components/StoreSearch');
var wishlist = require('../../wishlist');
var sliders = require('../../sliders');
var imageGallery;

var SiteConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/SiteConstants');
var TemplateConstants = require('../../../../../bc_sheplersbootbarn_core/cartridge/js/TemplateConstants');

var html = {};
var data = {};
var loaded = {};
var options;

function initializeDom() {
	loaded['content'] = true;
	
	updateCSRFToken();

	var selectorPayload = util.getQueryStringParams($('.pdpForm').serialize());
	
	var queryStringParameters = util.getQueryStringParams(util.getQueryString(util.appendParamsToUrl(window.location.toString(), selectorPayload)));
	for (var parameter in queryStringParameters) {
		selectorPayload[parameter] = queryStringParameters[parameter];
	}

	var sizeValue = util.getPreferenceValueFromWindowLocation('size');
	if (sizeValue) {
		selectorPayload.setSize = sizeValue;
	}
	
    buildSelectors(selectorPayload);
	$('.btn.add-to-cart').removeAttr('disabled').removeAttr('loading').find('.loading-indicator-container').remove();
}

function updateProductInfo() {
	$('li.selected-value').each(function() {
		var $selectedItem = $(this);
		$selectedItem.parents('.attribute').first().find('.label .current, .select-dropdown .select-text').html($selectedItem.html());
	});
}

function updateCSRFToken() {
    $.ajax({
    	method: 'get',
    	url: Urls.CSRFToken.Get,
		dataType: 'json',
    	success: function(response) {
    		$('#csrftoken').val(response.token);
    	}
    });
}

function updatePid() {
	if (data['pid']) {
		$('#pid').val(data['pid']);
	}
}

function buildSelectors(payload) {
	loaded['selectors'] = false;
	
	if (!payload) {
		payload = util.getQueryStringParams($('.pdpForm').serialize());
	}
    
    var templates = [];
    templates['variations'] = _.template($('#template-variations').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelector'] = _.template($('#template-variations-selector').html(), TemplateConstants.Lodash.Settings);
	templates['variationSelectorAttributeGuide'] = _.template($('#template-variations-attribute-guide').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorDropdown'] = _.template($('#template-variations-selector-dropdown').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorDropdownOption'] = _.template($('#template-variations-selector-dropdown-option').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorDropdownOptionSelected'] = _.template($('#template-variations-selector-dropdown-option-selected').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorSwatch'] = _.template($('#template-variations-selector-swatch').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorSwatchOption'] = _.template($('#template-variations-selector-swatch-option').html(), TemplateConstants.Lodash.Settings);
    templates['variationSelectorSwatchOptionUnselectable'] = _.template($('#template-variations-selector-swatch-option-unselectable').html(), TemplateConstants.Lodash.Settings);
    
	data['attributeGuides'] = {};

    $.ajax({
    	method: 'post',
    	url: Urls.getVariationAttributes,
    	data: payload,
		dataType: 'json',
    	success: function(response) {
			html['variationSelectors'] = '';
			data['IsRestrictedByGlobale'] = response.IsRestrictedByGlobale;

			// Color Swatch Length Check
			var isMultiSwatch;
			if (response && 'variations' in response && response.variations.length) {
				isMultiSwatch = response.variations.filter(function(variantGroup) {
					if (variantGroup && 'type' in variantGroup && 'values' in variantGroup) {
						return variantGroup.type == 'swatch' && variantGroup.values.length > 1; // Check for multiple color swatches
					}
				});
			}
			var hasSizeAttribute = false;
			if (response && 'variations' in response && response.variations.length) {
				hasSizeAttribute = response.variations.filter(function(variantGroup) {
					if (variantGroup && 'productVariationAttribute' in variantGroup && 'values' in variantGroup) {
						return variantGroup.productVariationAttribute == 'size' && variantGroup.values.length > 1;
					}
				});
			}

			var variations;
			if (hasSizeAttribute) {
				variations = response.variations;
			} else {
				variations = response.variations;
			}
			for (var variation in response.variations) {
				var variationData = response.variations[variation];
				var variationDataValueSelected = null;
				
				html['variationSelectorWidgetOptions'] = '';
								
				for (var variationValue in variationData.values) {
					var variationDataValue = variationData.values[variationValue];
					var variationDataValueTemplate;
					var variationText = (variationDataValue.attribute === 'size') ? variationDataValue.text.replace(' 1/2', '\u00BD') : variationDataValue.text;
					var widthDescription = variationDataValue.widthDescription ? '<span class="width-description">' + variationDataValue.widthDescription + '</span>' : '';
					var optionAriaLabel = variationDataValue.value;
					if (variationDataValue.shipping) {
						optionAriaLabel += + ' - ' + variationDataValue.shipping;
					}

					var variationDataValuePayload = {
						attribute: variationDataValue.attribute,
						valueId: variationDataValue.valueId,
						value: variationDataValue.value,
						title: variationDataValue.title,
						optionAriaLabel: optionAriaLabel,
						text: variationText,
						widthDescription: widthDescription,
						stock: variationDataValue.stock,
						shipping: variationDataValue.shipping,
						isSameDayShippingWindow: variationDataValue.isSameDayShippingWindow,
						quantitySourceType: variationDataValue.quantitySourceType,
						href: variationDataValue.href,
						selectable: variationDataValue.selectable ? 'selectable' : 'unselectable',
						selected: variationDataValue.selected ? 'selected' : '',
						swatchImageUrl: variationDataValue.images.swatch.url,
						largeSwatchImageUrl: variationDataValue.images.largeSwatch.url,
						hrefAttribute: variationDataValue.selectable ? 'href' : 'xhref'
					};
					
					if (variationData.type == 'swatch') {
						if (variationDataValue.selectable) {
							variationDataValueTemplate = templates['variationSelectorSwatchOption'];
						
							variationDataValuePayload.imageLarge = JSON.stringify({
								url: variationDataValue.images.large.url,
								title: variationDataValue.images.large.title,
								alt: variationDataValue.images.large.alt,
								hires: variationDataValue.images.hires.url
							});
						} else {
							variationDataValueTemplate = templates['variationSelectorSwatchOptionUnselectable'];
						}
					} else {
						variationDataValueTemplate = templates['variationSelectorDropdownOption'];
						
						if (variationDataValue.selected) {
							variationDataValueSelected = variationDataValue;
						}
					}
				
					html['variationSelectorWidgetOptions'] += variationDataValueTemplate(variationDataValuePayload);	
				}	
				
				// Add color swatch length boolean to template data
				if (variationData.type == 'swatch') {
					html['variationSelectorWidget'] = templates['variationSelectorSwatch']({
						attribute: variationData.productVariationAttribute,
						options: html['variationSelectorWidgetOptions'],
						multiSwatchDisplay: isMultiSwatch.length > 0 ? 'd-block' : 'd-none'
					});
				} else {
					var placeholder = variationData.label.placeholder,
						ariaLabel = variationData.label.placeholder;

					if (pageContext.site === SiteConstants.SiteIds.CountryOutfittersUS && variationData.productVariationAttribute === 'size' && variationDataValueSelected === null) {
						placeholder += ' (' + variationData.label.message + ')';
					}

					if (variationDataValueSelected) {
						var variationSelectedText = (variationData.productVariationAttribute === 'size') ? variationDataValueSelected.text.replace(' 1/2', '\u00BD') : variationDataValueSelected.text;
						var widthDescription = variationDataValueSelected.widthDescription ? '<span class="width-description">' + variationDataValueSelected.widthDescription + '</span>' : '';

						placeholder = templates['variationSelectorDropdownOptionSelected']({
							text: variationSelectedText,
							widthDescription: widthDescription,
							stock: variationDataValueSelected.stock,
							shipping: variationDataValueSelected.shipping,
							isSameDayShippingWindow: variationDataValueSelected.isSameDayShippingWindow,
							quantitySourceType: variationDataValueSelected.quantitySourceType
						});

						ariaLabel = 'Currently Selected: ' + variationDataValueSelected.value; 
						if (variationDataValueSelected.shipping) {
							ariaLabel += ' - ' + variationDataValueSelected.shipping;
						}
					}

					// Add color swatch length boolean to template data
					html['variationSelectorWidget'] = templates['variationSelectorDropdown']({
						attribute: variationData.productVariationAttribute,
						text: placeholder,
						ariaLabel: ariaLabel,
						options: html['variationSelectorWidgetOptions'],
						multiSwatchDisplay: isMultiSwatch.length > 0 ? 'd-block' : 'd-none'
					});
				}

				html['attributeGuide'] = '';
				if (variationData.attributeGuide) {
					data['attributeGuides'][variationData.productVariationAttribute] = variationData.attributeGuide;
					html['attributeGuide'] = templates['variationSelectorAttributeGuide'](_.extend(variationData.attributeGuide, { Attribute: variationData.productVariationAttribute }));
				}
				
				html['variationSelector'] = templates['variationSelector']({
					attribute: variationData.productVariationAttribute,
					label: {
						text: variationData.label.text,
						message: variationData.label.message
					},
					selector: html['variationSelectorWidget'],
					guide: html['attributeGuide']
				});

				html['variationSelectors'] += html['variationSelector'];
			}
			
			html['selectors'] = templates['variations']({
				selectors: html['variationSelectors']
			});
			
			data['selected'] = response.selected;
			data['pid'] = response.pid;
			
			loaded['selectors'] = true;
			renderVariationUpdates();
    	}
	});
}

function setAlternates() {
	$('ul.alternate-items li').sort(function(a,b) { return $(a).data('id') - $(b).data('id'); }).appendTo('.alternate-items');
	var altType = $('ul.alternate-items').data('alternate');
	var altDefault = $('ul.alternate-items').data('default');
	var alternates = [];

	var isSubscriptionProduct = $('.pdp-main').hasClass('subscription-product-template');
	var colorSelected = $('.color-options .selected a');
	var sizeSelected = $('.size-options .selected a').data('size-id');

	if (colorSelected && isSubscriptionProduct) {
		alternates.push({'altID': $(colorSelected).parent('.selected').data('id'), 'altValue': $(colorSelected).attr('data-color-name')});
	}
	$('ul.alternate-items li').each(function(i, elem) {
		alternates.push({'altID': $(elem).data('id'), 'altValue': $(elem).text().split('-')[1]});
	});

	if (sizeSelected && sizeSelected.length > 0 && $('.alternate').length > 0){
		updateAlternates(sizeSelected);
	} else if (alternates && alternates.length > 0){
		$.ajax({
			url: Urls.getItemAlternates,
			dataType: 'JSON',
			method: 'POST',
			data: {
				'alternates': JSON.stringify(alternates)
			},
			success : function(data) {
				var altSwatches = '';
				var alternate = data.result;
				
				$.each(alternate, function(i, e){
					var id = alternate[i].id;
					var type = altType ? altType : 'Color';
					var value = alternate[i].value;
					var image = alternate[i].image;
					var url = $('.alternate-items li[data-id='+id+']').data('url');
					var altHidden = 'show-content';
					if ( i >= 11 ){
						altHidden = 'hidden-content';
					}
					if ( isSubscriptionProduct && id == $(colorSelected).parent('.selected').data('id') ){
						$(colorSelected).css('background', 'url('+image+')');
					} else {
						altSwatches += '<li class="unselectable alternate ' + altHidden + '" data-id="'+id+'" data-type="'+altType+'" data-value="'+value+'"><a class="swatchanchor" href="'+url+'" title="Select '+altType+': '+value+'" value="'+value+'" style="background: url('+image+'?sw=70&amp;sh=70&amp;sm=fit); background-repeat: no-repeat; background-size: contain; background-position: center;"><span class="visually-hidden">Select '+altType+':'+value+'</span></a></li>';
					}
				});
				
				if (altSwatches.length > 0 ){
					$('.color.color-options').removeClass('color-d-none').append(altSwatches).find('li').sort(function(a,b) { return $(a).data('id') - $(b).data('id'); }).appendTo('.color-options');
					if (altType && altDefault){
						$('.color-options .selected').attr('data-type',altType).attr('data-value',altDefault).find('a.swatchanchor').attr('title','Select '+altType+': '+altDefault);
						var newText = '<span class="text">'+altType+'<span class="separator">:</span> <span class="current color-current">'+altDefault+'</span></span>';
						$('.attribute-color .text').html(newText);
					}
				}
				
				if (sizeSelected && sizeSelected.length > 0){
					updateAlternates(sizeSelected);
				}
				
				var altHiddenCount = $('.hidden-content').length;
				var altHiddenToggle = '+ ' + altHiddenCount;
				if (altHiddenCount > 0){
					$('.hidden-content').hide();
					$('.color.color-options').append('<button class="altButton">' + altHiddenToggle + '</button>');
				}

				$('button.altButton').on('click', function(e){
					e.preventDefault();
					$(this).closest('.color-options').find('.hidden-content').toggle();
					$('.altButton').hide();
				})
				
				$(".color-options li").hover(function(){
					var altTitle = $(this).data('type');
					var altValue = $(this).data('value');
					var newText = '<span class="text">'+altType+'<span class="separator">:</span> <span class="current color-current">'+altValue+'</span></span>';
					$('.attribute-color .text').html(newText);
				}, function(){
					var altTitle = $('.color-options .selected').data('type');
					var altValue = $('.color-options .selected').data('value');
					var newText = '<span class="text">'+altType+'<span class="separator">:</span> <span class="current color-current">'+altValue+'</span></span>';
					$('.attribute-color .text').html(newText);
				});
			}
		});
	}
}

function updateAlternates(sizeSelected) {
	var altupdates = [];
	$('ul.color-options li').each(function(i, elem) {
		altupdates.push(String($(elem).data('id')));
	});
	
	if(altupdates && altupdates.length > 0){
		$.ajax({
			url: Urls.updateItemAlternates,
			dataType: 'JSON',
			method: 'POST',
			data: {
				'altupdates': JSON.stringify(altupdates),
				'size': sizeSelected
			},
			success : function(data) {
				var alternate = data.result;
				
				$.each(alternate, function(i, e){
					var id = alternate[i].id;
					var appendURL = alternate[i].appendURL;
					var sizeAvailable = !alternate[i].sizeAvailable;
					var oldURL = $('ul.color-options li[data-id='+id+'] a').attr('href');
					var newURL = oldURL + appendURL;
					$('ul.color-options li.alternate[data-id="'+id+'"] a').attr('href',newURL);
					if(appendURL == null || (typeof appendURL === "string" && appendURL.trim().length === 0)){
						$('ul.color-options li[data-id='+id+'] a').addClass('no-size');
					}
				});
			}
		});
	}
}

function renderVariationUpdates() {
	if (loaded['content'] && loaded['selectors']) {
		renderSelectors();
		updatePid();
		setAlternates();
		
		$(document).trigger('SameDayShippingCountdown.Update');
		
		var sizeSelected = $('div.product-variations .size.size-options li.selected');
		var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
		var widthSelected = $('div.product-variations .width.width-options li.selected').length;
		var isSizeSelected = sizeSelected.length || (lengthSelected && widthSelected);
		var isColorSelected = $('div.product-variations .color.color-options li.selected').length;
		
		updateWishlistButton(sizeSelected);

		if (isSizeSelected) {
			$('.attribute-size').addClass('size-selected');
		} else {
			$('.attribute-size').removeClass('size-selected');
		}
	    if (isSizeSelected && isColorSelected) {
			$('.btn.store-search').removeAttr('disabled');

	    	$(document).trigger('ProductDetailPage.Variant.Selected');
			$(document).trigger('ProductDetailPage.Variant.AddToCartOptions');
			$(document).trigger('ProductDetailPage.Variant.StoreSearch');
			$(document).trigger('Currency.Update', $('#product-content'));
	    }
	    
    	var $selectableCount = 0;
    	$('.product-options .attribute-size .select-dropdown ul.size-options li, .product-options .attribute-size .select-dropdown ul.length-options li').each(function() {
        	var $selectClass = $(this).attr('class').replace('selected','').trim();
        	if( $selectClass == 'selectable'){
        		$selectableCount++;
        	}        
        });

		if (data['IsRestrictedByGlobale'] || $selectableCount == 0) {
			$('.same-day-shipping-countdown').addClass('hidden');
			$('.product-options .attribute-size .select-dropdown').css({"pointer-events": "none", "cursor": "default"});
			$('.product-variation').addClass('not-available');
			if ($('.color-options .selectable').length > 1 || $('.alternate-items li').length > 1) {
				$('.product-variation').addClass('colors-selectable');
			}
			if (data['IsRestrictedByGlobale']) {
				$('.product-globale-unavailable').removeClass('hidden');
			} else if ($selectableCount == 0) {
				$('.product-out-of-stock').removeClass('hidden');
			}
		}

		$(document).trigger('ProductDetailPage.Variant.Updated');
	}
}

function renderSelectors() {
	$('.product-variations').html(html['selectors']);
	$('.product-variations').attr('data-attributes', JSON.stringify(data['selected']));
	checkForSelectedSwatch($('.product-variations'));

	$('.attribute-guide-link').fancybox();
}

function checkForSelectedSwatch($container) {
	var swatchIsSelected = $container.find('.color-options .selectable.selected').length > 0;
	if (!swatchIsSelected) {
		$container.find('.color-options .selectable:first-child>.swatchanchor').mouseenter();
	}
}

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
	loaded['content'] = false;
	$('.btn.add-to-cart').attr('disabled', 'disabled').loading();
	var bopisStoreSearchPostalCode = $('.bopis-store-search input[name="Bopis_StoreSearch_postalCode"]').val();
    var $pdpForm = $('.pdpForm');
    var qty = $pdpForm.find('select[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $pdpForm.find('input[name="pid"]').first().val(),
        BopisEnabled: $('.bopis-store-search').length > 0 ? true : false,
		Token: $('.bopis-store-search input[name="csrf_token"]').val()
    };

    buildSelectors(util.getQueryStringParams(util.getQueryString(util.appendParamsToUrl(href, params))));
	$.ajax({
    	method: 'GET',
    	url: util.appendParamsToUrl(href, params),
    	complete: function(html) {
			loaded['content'] = true;
			$('#product-content').html(html.responseText);
			renderVariationUpdates();

			if (bopisStoreSearchPostalCode) {
        		$('.bopis-store-search input[name="Bopis_StoreSearch_postalCode"]').val(bopisStoreSearchPostalCode);
        	}
        	
            if (SitePreferences.STORE_PICKUP) {
                productStoreInventory.init();
            }
            image.replaceImages();
            tooltip.init();
            // rating.init();
            updateProductInfo();
            initializeImageGallery();            
			updateCSRFToken();
			// GoogleTagManagerClient.PushDataLayerForDataLayerType('ViewItem');
            
            setTimeout(function(){$(document).trigger('update-review-text');}, 500);

            if ('affirm' in window && 'ui' in affirm && 'refresh' in affirm.ui) {
			    affirm.ui.refresh();
			}
            
			var variantSelected = isVariantSelected();
            if ($('.bopis-store-search').length > 0 || $('.bopis-add-to-cart-options-container').length > 0) {
            	if (variantSelected) {
            		options.Bopis.IsVariantSelected = true;
            	}
				initializeBopis();
			}

			if (pageContext.site === SiteConstants.SiteIds.BootBarnUS) {
				updateAddToCartRecommendations();
			}
			$('.btn.add-to-cart').removeAttr('disabled').removeAttr('loading').find('.loading-indicator-container').remove();    		
    	}
    });
};

function isVariantSelected() {
	var sizeSelected = $('div.product-variations .size.size-options li.selected').length;
	var lengthSelected = $('div.product-variations .length.length-options li.selected').length;
	var widthSelected = $('div.product-variations .width.width-options li.selected').length;
	var colorSelected = $('div.product-variations .color.color-options li.selected').length;

	return (sizeSelected || (lengthSelected && widthSelected)) && colorSelected;
}

function updateAddToCartRecommendations() {
	var intervalCount = 0;
	var addToCartRecommendationsCheck = setInterval(function() {
		if ( $('.recomm-prod-pdp-a2c-slot .accordion.product-recommendations').length > 0 ) {
			clearInterval(addToCartRecommendationsCheck);
			initSlider();
			return;
		}

		if (intervalCount === 50) {
			clearInterval(addToCartRecommendationsCheck);
			return;
		}

		intervalCount++;
	}, 200);

	function initSlider() {
		sliders.init('.recomm-prod-pdp-a2c-slot .accordion.product-recommendations .slides', {breakpoints: {0: {slidesToShow: 1, arrows: true, dots: true}, 768: {slidesToShow: 3, arrows: true, dots: false}, 1024: {slidesToShow: 4, arrows: true, dots: false}}}, true);
	}
}

function sizeMenuOpenClose(event, el, open) {
	var $this = el;
	var $container = $this.closest('.select-dropdown');
	var $menu = $container.find('.select-dropdown-inner');
	
	if (open) {
		$this.addClass('active');
		$menu.addClass('expand');
		$menu.find('li.selectable a').removeAttr('tabindex');
	} else {
		$this.removeClass('active');
		$menu.removeClass('expand');
		$menu.find('li.selectable a').attr('tabindex', '-1');
		if ($menu.find('.tempselection').length > 0) {
			$menu.find('.tempselection').removeClass('selected tempselection');
		}
		if (event.keyCode) {
			$container.addClass('focus-visible-indicator').focus();
		}
	}

	if ($menu.hasClass('expand')) {
		scrollToCurrentSize();
		setTimeout(function(){
			$menu.addClass('expand-close');
		}, 100);
	} else {
		$menu.removeClass('expand-close');
	}
}

function selectSizeOption(el) {
	var $selected = $(el);
	
	$selected
		.addClass('selected')
		.siblings()
		.removeClass('selected');

	$selected
		.closest('.select-dropdown-inner')
		.removeClass('expand')
		.removeClass('expand-close')
		.prev('.select-text')
		.removeClass('active');
}

function updateWishlistButton(sizeSelected) {
	var variantId = $('#pid').val();
	var isWishlistItem = wishlist.checkWishlistForItem(variantId);
	var sizeText = '';

	if (sizeSelected) {
		sizeText = sizeSelected.find('.size').text();
	}
	if (isWishlistItem) { 
		wishlist.prepareWishlistRemoveUI($('.pdp-main .wishlist-item-status-button'), sizeText);
	} else {
		wishlist.prepareWishlistAddUI($('.pdp-main .wishlist-item-status-button'), null, sizeText);
	}
}

function initializeEvents () {
	var $pdpMain = $('#pdpMain');

    // hover on swatch - should update main image with swatch image.
//    $pdpMain.off('mouseenter.Variant.SwatchAnchor').on('mouseenter.Variant.SwatchAnchor', '.color>li>.swatchanchor', function (e) {
//        e.preventDefault();
//        if ($(this).parents('li').hasClass('unselectable')) { return; }
//        if ($(this).parents('li').hasClass('selected')) { return; }
//        updateContent(this.href);
//    });

    // click on swatch - should update main image with swatch image.
    $pdpMain.off('click.Variant.SwatchAnchor').on('click.Variant.SwatchAnchor', '.product-detail .swatchanchor', function (e) {
		e.preventDefault();
        var didReload = false;
        if ($(this).parents('li').hasClass('unselectable')) {
        	if ($(this).parents('li').hasClass('alternate')) {
        		var altUrlLoad = $(this).attr('href');    		
        		window.location.href = altUrlLoad;
        	}
        	
        	$('li.drop-down-select-value').each(function() {
    			var $selectedItem = $(this);
    			$selectedItem.siblings('li.selected-value').html($selectedItem.html());
        		$selectedItem.parents('.attribute').first().find('.label .current, .select-dropdown .select-text').html($selectedItem.html());
        		if ( $selectedItem.parents('.select-dropdown-inner') != null ) {
        			$selectedItem.parents('.select-dropdown-inner').find( 'li.selected' ).removeClass('selected');
        		}
        	});
    		if ( $(this).attr('xhref') != null ) {
    			updateContent($(this).attr('xhref'));
    			didReload = true;
    		} else { return; }
    	}
        if ( !didReload ) {
	        var link = this;
	        if (!link.href) {
	        	link = $(this).parent()[0];
	        }
	        updateContent(link.href);
        }
    });

    // change drop down variation attribute - should replace product content with new variant
    $pdpMain.off('change.Variant.VariationSelect').on('change.Variant.VariationSelect', '.variation-select', function () {
        if ($(this).val().length === 0) { return; }
        updateContent($(this).val());
    });

	// Custom Select Dropdown
    $pdpMain.off('click.Variant.Selector.Expand').on('click.Variant.Selector.Expand', '.select-dropdown .select-text', function (event) {
		event.preventDefault();
		var $this = $(this);
		var toggleOpen = !$this.hasClass('active');
		sizeMenuOpenClose(event, $this, toggleOpen);
	});

    $(document).off('click.Variant.General.Close').on('click.Variant.General.Close', function(event) {
    	$('div.product-variations ul.select-dropdown-inner.expand-close').each(function () {
    		$(this).siblings('.select-text').trigger('click');
    	});
	});

	$pdpMain.off('click.Variant.Selector.Select').on('click.Variant.Selector.Select', '.select-dropdown .selectable', function(event) {
		event.preventDefault();
		selectSizeOption(this);
	});

	// Color Options
    $pdpMain.off('click.Variant.Color.Select').on('click.Variant.Color.Select', '.color-options a', function (event) {
		event.preventDefault();

		var nameColor = $(this).data('color-name');

		$(this)
			.parent()
			.addClass('selected')
			.siblings()
			.removeClass('selected');

		$(this)
			.closest('.attribute')
			.find('.color-current')
			.text(nameColor);
	});
    
    $(document).on('keyup', 'div.product-variations div.select-dropdown', function(e) {
		var $listContainer = $(this),
			$listParent = $listContainer.children('ul.select-dropdown-inner'),
			$defaultTextElement = $listContainer.children('.select-text'),
			$sizeList = $listParent.find('li.selectable'),
			$selected = $sizeList.filter('.selected'),
			isMenuOpen = $listParent.hasClass('expand');

		antiHover.init($listParent.width(), $listParent.height());

		if (e.shiftKey && e.keyCode == 9 && isMenuOpen) { // Tab backwards
			selectOptionKeydown(e, 'up', isMenuOpen);
		} else {
			switch(e.keyCode) {
				case 38: // Up Arrow
					selectOptionKeydown(e, 'up', isMenuOpen);
					break;
				case 40: // Down Arrow
					if (!isMenuOpen) {
						sizeMenuOpenClose(e, $defaultTextElement, true);
					}
					selectOptionKeydown(e, 'down', isMenuOpen);
					break;
				case 9: // Tab forwards
					if (isMenuOpen) {
						selectOptionKeydown(e, 'down', isMenuOpen);
					}
					break;
				case 27: // Escape key
					if (isMenuOpen) {
						sizeMenuOpenClose(e, $defaultTextElement, false);
					}
					break;
				case 13: // Enter key
					$listContainer.find('.select-text').toggleClass('active');
					$listParent.toggleClass('expand').toggleClass('expand-close').toggleClass('size-d-none');
					$sizeList.find('a[tabindex]').length > 0 ? $sizeList.find('a').removeAttr('tabindex') : $sizeList.find('a').attr('tabindex', '-1');	
					if (!$selected) {
						$defaultTextElement.trigger('click');
					} else {
						$selected.find('a.swatchanchor').trigger('click');
					}	
					break;
			}
		}
	});

    $(document).off('mousemove.Variant.AntiHover').on('mousemove.Variant.AntiHover', '#anti-hover', function(e) {
    	antiHover.moveremove(parseInt(e.clientX), parseInt(e.clientY));
    });

    var swatches = $('div.product-variation ul.color-options li.selectable');
	if (swatches.length && !swatches.is('.selected')) {
		setColor();
	}
	
	updateProductInfo();
    initializeImageGallery();
};

function setColor(){
	var d = new $.Deferred();

	setTimeout(function(){
    	var swatches = $('div.product-variation ul.color-options li.selectable');
    	if (swatches.length && !swatches.is('.selected')) {
    		swatches.first().find('a').trigger('click');
    		setTimeout(function(){d.resolve()}, 150);
    	}
    	
    }, 50);
	
	return d.promise()
}

var antiHover = {
	$antiHover: null,
	posX: 0,
	posY: 0,
	remove: function() {
		if (this.$antiHover) this.$antiHover.remove();
		this.posX = 0;
		this.posY = 0;
	},
	moveremove: function(x, y) {
		this.posX = (this.posX == 0) ? x : this.posX;
		this.posY = (this.posY == 0) ? y : this.posY;
		if (this.posX != x || this.posY != y) {
			$('div.product-variations ul.select-dropdown-inner li.selectable').removeClass('selected');
			this.remove();
		}
	},
	check: function() {
		return (this.$antiHover && this.$antiHover.length);
	},
	init: function(w, h) {
		this.remove();
		$('.value.select-dropdown').append('<div style="position: absolute;" id="anti-hover"></div>');
		this.$antiHover = $('#anti-hover');
		this.$antiHover
			.css('z-index', 1000)
			.css('height', h)
			.css('width', w);
	}

};

function selectOptionKeydown(e, action, isMenuOpen) {
	e.preventDefault();

	var $listParent = $('div.product-variations ul.select-dropdown-inner'),
		$listContainer = $listParent.closest('.select-dropdown'),
		$defaultTextElement = $listContainer.children('.select-text'),
		$sizeList = $listParent.find('li.selectable'),
		$selected = $sizeList.filter('.selected'),
		height = parseInt($sizeList.first().css('height').replace(/px/, '')),
		$current;

		if ($selected.length <= 0) {
			$selected = $listParent.find('.focus-visible-indicator').closest('li.selectable') ? $selected : null;
		}
		if (!$selected || !$selected.length) {
			$current = $sizeList.eq(0);
		} else if (action == 'up') {
			if ($selected.is(':first-child')) {
				$current = $sizeList.eq(0);
				// If this is first list item, and user pressed Up Arrow, then we need to close the menu
				sizeMenuOpenClose(e, $defaultTextElement, false);
			} else {
				$current = $selected.prev();
			}
		} else {
			if (!$selected.next().hasClass('selectable')) {
				$current = $sizeList.last();
				// If this is last list item, and user pressed Down Arrow, then we need to close the menu
				sizeMenuOpenClose(e, $defaultTextElement, false);
			} else {
				$current = $selected.next();
			}
		}

		var listParentTop = 0;
		var listParentBottom = listParentTop + $listParent.height();
		var currentTop = $current.position().top;
		var currentBottom = currentTop + $current.height();
		var faultPx = 10;
		if (currentBottom + faultPx > listParentBottom) {
			$listParent.scrollTop(parseInt($listParent.scrollTop()) + height);
		} else if (currentTop < listParentTop) {
			$listParent.scrollTop(parseInt($listParent.scrollTop()) - height);
		}	

		//$current.parents('.attribute').first().find('.label .current, .select-dropdown .select-text').html($current.find('a').html());
		$sizeList.removeClass('selected tempselection');
		$current.addClass('selected tempselection').find('a').addClass('focus-visible-indicator').focus();
		// Needed to combat auto-scroll behavior that happens in previous line when focus added to <a>
		if ($current.is(':first-child')) {
			$listParent.scrollTop(0);
		}
}

function scrollToCurrentSize() {
	var $listParent = $('div.product-variations ul.select-dropdown-inner'),
		$sizeList = $listParent.find('li.selectable'),
		$selected = $sizeList.filter('.selected'),
		height = $sizeList.first().css('height').replace(/px/, '');

	if ($selected) {
		$listParent.scrollTop(height * $sizeList.index($selected));
	}
}

function initializeImageGallery() {
	var params;
	if (SitePreferences.PRIMARY_PRODUCT_SLIDER == 'swiperJS') { 
		imageGallery = require('../../components/ImageGallery/Swiper');
		if (SitePreferences.PRIMARY_PRODUCT_SLIDER_CONFIG) {
			params = JSON.parse(SitePreferences.PRIMARY_PRODUCT_SLIDER_CONFIG);
		}
	} else {
		imageGallery = require('../../components/ImageGallery/SlickAndImgViewer2');
	}
	imageGallery.initialize(params);
}

function initializeBopis() {
	if ($('.bopis-add-to-cart-options-container').length > 0) {
		bopisAddToCartOptions.initialize($('.bopis-add-to-cart-options-container'), $.extend(options.Bopis, { Channel: options.Channel, ProductId: options.ProductId, StoreUIVersion: options.StoreUIVersion }));
	}
	
	if ($('.bopis-store-search').length > 0) {
		bopisStoreSearch.initialize($('.bopis-store-search'), $.extend(options.Bopis, { Channel: options.Channel }));
	}
}

function initialize(optionsParameter) {
	options = optionsParameter;
	initializeDom();
	initializeEvents();
	initializeBopis();
}

var variant = {
	initialize: initialize,
	initializeDom: initializeDom,
	initializeEvents: initializeEvents
};

module.exports = variant;